<template>
  <div class="page-error">
    <div class="info-error-msg-div">
      <div class="close-page-erreur-div"><i class="fa-regular fa-circle-xmark" @click="closeWindows"></i></div>
      <p class="info-error-msg">Quelque chose s'est mal passé!</p>
      <p class="info-error-submsg">Nous sommes désolés pour ce désagrément et nous nous efforçons de vous offrir la meilleure expérience utilisateur possible.</p>
      <p class="info-error-submsg">En cas de besoin, vous pouvez contacter le support technique par e-mail à : </p>
      <p class="info-error-submsg"><a href="mailto:support@touriz.fr">Touriz</a></p>
      <p class="info-error-submsg">N'hésitez pas à ajouter une capture d'écran du problème rencontré.</p>
      <p class="info-error-code"> Code erreur: {{reason.error_code}}</p>
      <img class="info-error-img" src="maintenance.png"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageError",
  props: {
    reason: Object
  },
  emits: ['closeWindows'],
  methods: {
    closeWindows(){
      this.$emit('closeWindows');
    }
  }
}
</script>

<style scoped>
.page-error{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info-error-msg-div{
  width: 30%;
  height: 50%;
  border-radius: 10px;
  background-color: #f3b61b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.info-error-msg{
  font-size: 25px;
  font-weight: bold;
}
.info-error-submsg{
  font-size: 14px;
  margin: 2px;
}
.info-error-img{
  width: 200px;
  margin: auto;
}
.close-page-erreur-div{
  width: 100%;
  height: 20px;
  display: flex;
  font-size: 20px;
  justify-content: flex-end;
}
.fa-circle-xmark{
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
}
.fa-circle-xmark:active{
  font-size: 18px;
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.info-error-code{
  font-size: 20px;
  margin-top: 40px;
  padding: 3px;
  border-radius: 10px;
  background-color: #7a0202;
  color: #fefefe;

}
</style>