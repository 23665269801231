<template>
  <div class="list-loader" :id="'list-loader-'+type">
    <div class="circle circle-1"></div>
    <div class="circle circle-2"></div>
    <div class="circle circle-3"></div>
    <div class="circle circle-4"></div>
    <div class="circle circle-5"></div>
    <div class="circle circle-6"></div>
    <div class="circle circle-7"></div>
    <div class="circle circle-8"></div>
    <div class="circle circle-9"></div>
  </div>
</template>

<script>
export default {
  name: "ListLoader",
  props:{
    type : String
  }
}
</script>

<style scoped>
.list-loader{
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: none;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  opacity: 0.7;
  animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
}

.circle-1 {
  background-color: #82e549;
}
.circle-1:before {
  background-color: #f3b61b;
  animation-delay: 200ms;
}

.circle-2 {
  background-color: #e57147;
}
.circle-2:before {
  background-color: #f3b61b;
  animation-delay: 400ms;
}

.circle-3 {
  background-color: #d0a92a;
}
.circle-3:before {
  background-color: #f3b61b;
  animation-delay: 600ms;
}

.circle-4 {
  background-color: #ae3ac8;
}
.circle-4:before {
  background-color: #f3b61b;
  animation-delay: 800ms;
}

.circle-5 {
  background-color: #ba0f28;
}
.circle-5:before {
  background-color: #f3b61b;
  animation-delay: 1000ms;
}
.circle-6 {
  background-color: #82e549;
}
.circle-6:before {
  background-color: #f3b61b;
  animation-delay: 1200ms;
}

.circle-7 {
  background-color: #e57147;
}
.circle-7:before {
  background-color: #f3b61b;
  animation-delay: 1400ms;
}

.circle-8 {
  background-color: #d0a92a;
}
.circle-8:before {
  background-color: #f3b61b;
  animation-delay: 1600ms;
}

.circle-9 {
  background-color: #ae3ac8;
}
.circle-9:before {
  background-color: #f3b61b;
  animation-delay: 1800ms;
}


@keyframes scale {
  0% {
    transform: scale(1);
  }
  50%, 75% {
    transform: scale(2.5);
  }
  78%, 100% {
    opacity: 0;
  }
}
</style>