<template>
  <div class="connect-form">
    <div id="touriz-logo-accueil"><img id="logo-touriz-accueil" src="logo_touriz.png"></div>
    <h1>L'Observatoire des locations meublées touristiques<br/><div style="font-weight: normal;">(données ELAN)</div></h1>
    <div>
      <div class="connect-form-div"><label class="connect-form-label">Identifiant / Email</label><input id="input-login-connect" class="connect-form-input" value=""/></div>
      <div class="connect-form-div"><label class="connect-form-label">Mot de passe</label><input id="input-password-connect" type="password" class="connect-form-input" value=""/></div>
      <div v-if="entities_user.length > 1" class="connect-form-div"><label class="connect-form-label">Commune</label>
        <select class="connect-form-input" id="select-entity-connect">
          <option v-for="entity in entities_user" :key="entity" :value="entity.entity">{{ firstToUpper(entity.entity) }}</option>
        </select>
      </div>
      <button class="connect-form-button" @click="callUserEntity">Se connecter</button>
      <div><button class="btn-recover-pass" @click="recoverPW">Mot de passe oublié</button></div>
      <div class="link-admin-touriz"><a class="link-admin-touriz-a" href="https://admin-touriz.fr" target="_blank">Se connecter à Admin-touriz</a></div>
      <div class="alert-red" id="alert-connect">Erreur</div>
    </div>
    <div class="rgdp-cgu-infos">
      <p>TOURIZ utilise des cookies techniques nécessaire au bon fonctionnement de l'application.</p>
      <p>Vous pouvez consulter nos <a href="media/TOURIZ CGU ELAN COMMUNE - mai 2024.pdf" target="_blank">CGU</a> ainsi que notre <a href="media/TOURIZ RGPD - mai 2024.pdf" target="_blank">Politique RGPD</a> pour plus d’informations.</p>
    </div>

<!--    <span class="powered-by">Powered by <img class="logo-touriz" src="logo_touriz.png"></span>-->
  </div>
  <div id="background-connect-limit"></div>
  <div id="background-connect-img"></div>
</template>

<script>
import volt from "../volt";
export default {
  name: "ConnectForm",
  emits: ['callUserEntity'],
  props: {
    entities_user : Object,
    env : Object
  },
  methods: {
    callUserEntity(){
      this.$emit('callUserEntity');
    },
    recoverPW(){
      window.location.href = this.env.paths.front+'?recover_mdp=email';
    },
    firstToUpper(str){
      return volt.firstToUpper(str);
    }
  }
}
</script>

<style scoped>
a{
  color: #343434;
}
a:hover{
  color: #f3b61b;
}
.connect-form{
  z-index: 5000;
  grid-column-start: 1;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 21;
  background-color: #fefefeee;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /*box-shadow: 1px 1px 5px #7f7e7c;*/

}
#background-connect-limit{
  z-index: 4000;
  grid-column-start: 11;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 21;
  background: linear-gradient(to right top, #fefefeee 50%, transparent calc(50% + 2px));
}
#background-connect-img{
  z-index: 1000;
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 21;
  background-image:url('../../public/Touriz_Observatoire_Connexion.jpg');
  background-repeat:no-repeat;
  background-position:center center;
  background-size: cover;
}
.connect-form-div{
  display: flex;
  flex-direction: row;
  height: 60px;
}
.connect-form-label{
  width: 40%;
  margin: 5px 20px 0 20px;
  text-align: end;
  font-size: 20px;
  font-weight: bold;
}
.connect-form-input{
  margin: 0 20px 20px 0;
  width: 40%;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  text-align: start;
  padding: 3px 3px 3px 5px;
  background-color: #dedede;
}
#select-entity-connect{
  width: 40%;
}
.connect-form-button{
  background-color: #f3b61b;
  font-weight: bold;
  margin: 10px 25px 0 25px;
  width: 70%;
  height: 40px;
  font-size: 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.connect-form-button:active{
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.powered-by{
  font-size: 8px;
  text-align: end;
  margin: 5px 15px 0 15px;

}
.logo-touriz{
  width: 60px;
  position: relative;
  top: 8px;
}
#touriz-logo-accueil{
  display: flex;
  width: 100%;
  justify-content: start;
  margin-left: 80px;
}
#logo-touriz-accueil{
  width: 300px;
}
.btn-recover-pass{
  padding: 10px;
  text-decoration-line: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 15px;
}
.btn-recover-pass:hover{
  color: #f3b61b;
}
.link-admin-touriz{
  /*background-color: #7a7a7a;*/
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.link-admin-touriz-a {
  background-color: #dedede;
  padding: 10px;
  border-radius: 10px;
  color: #343434;
  font-size: 15px;
  text-decoration: none;
}
.rgdp-cgu-infos{
  width: 100%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.rgdp-cgu-infos p{
  width: 80%;
  text-align: center;
  margin: 0;
}
@media (max-width: 1050px) {
  .connect-form{
    grid-column-start: 1;
    grid-column-end: 21;
    grid-row-start: 1;
    grid-row-end: 21;
    background-color: #fefefeaa;
  }
  #background-connect-img{
    /*display: none;*/
  }
  #background-connect-limit{
    display: none;
  }
  #touriz-logo-accueil{
    margin-left: 10%;
  }
  #logo-touriz-accueil{
    width: 200px;
  }
  .link-admin-touriz{
    display: none;
  }
}
</style>