<template>
  <div class="kpi-details">
    <div class="kpi-title" >
      <i v-if="display === 'none'" class="fa-solid fa-square-caret-right" @click="openDetails"></i>
      <i v-else class="fa-solid fa-square-caret-down" @click="openDetails"></i>
      <span class="span-title" @click="openDetails">{{title}}</span>
      <i class="fas fa-ellipsis-h" @click="openPopMenu"></i>
    </div>
    <div :id="'pop-menu-kpis-'+title" class="pop-menu-kpis" @mouseleave="closePopMenu"><div :id="'sub-pop-menu-kpis-'+title" class="sub-pop-menu-kpis"><i class="fas fa-sync"  @click="syncKpis"></i><i class="fas fa-print"></i><i class="fas fa-ellipsis-h"></i></div></div>
    <KpiLoader :title="title"/>
    <div class="kpi-div-content-details" :id="'kpi-details-'+title">
      <kpi-list v-for="kpi in kpis" :key="kpi" :title="kpi.title" :kpis="kpi.details" :style="kpi.style" :value="kpi.value" :link="kpi.link" :limit="limit" @callList="callList" :map_filter = "map_filter"/>
    </div>
  </div>
</template>

<script>
import KpiList from "@/components/KpiList";
import KpiLoader from "@/components/KpiLoader";
import volt from "@/volt";
export default {
  name: "KpiDetails",
  emits: ["callList", "syncKpis"],
  components: {KpiList,KpiLoader},
  props:{
    title : String,
    kpis : Object,
    style : Object,
    open : String,
    limit : Number,
    map_filter : Number
  },
  mounted() {
    this.display = this.open;
    // document.getElementById('kpi-details-'+this.title).style.display = this.open;
  },
  data(){
    return{
      display : 'block'
    }
  },
  methods:{
    syncKpis(){
      volt.tak('kpi-loader-'+this.title).style.display = "flex";
      volt.sync = this.title;
      this.$emit('syncKpis');
    },
    openPopMenu(){
      setTimeout(()=>{volt.tak('sub-pop-menu-kpis-'+this.title).style.display = 'flex';},150);
      volt.tak('pop-menu-kpis-'+this.title).style.height = '30px';


    },
    closePopMenu(){
      setTimeout(()=>{
        setTimeout(()=>{volt.tak('sub-pop-menu-kpis-'+this.title).style.display = '';},20)
        volt.tak('pop-menu-kpis-'+this.title).style.height = '';
      },1000)
    },
    openDetails(){
      if(this.display === 'block' || this.display === ''){
        this.display = 'none';
      }else{
        this.display = 'block';
      }
    },
    callList(){
      this.$emit('callList');
    }
  }
}
</script>

<style scoped>
.kpi-details{
  height: 100%;
  padding-left: 5%;
}
.kpi-div-content-details{
  display: v-bind(display);
  height: 100%;
  transition: 0.5s;
}
.kpi-title{
  text-align: start;
  font-size: 14px;
  margin: 5px 5px 20px 5px;
  padding: 3px;
  border-bottom: solid 1px #2c3e50;
}
.fa-solid{
  font-size: 14px;
  margin-right: 5px;
  cursor: pointer;
}
.fa-ellipsis-h{
  float: right;
  cursor: pointer;
}
.fa-ellipsis-h:hover , .fa-print:hover ,.fa-sync:hover{
  color: #f3751b;
}
.fa-sync, .fa-print{
  cursor: pointer;
}
.span-title{
  cursor: pointer;
}
.pop-menu-kpis{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  margin-right: 5px;
  margin-top: 5px;
  height: 0;
  background-color: #f3b61bcc;
  position: relative;
  top: -20px;
  float: right;
  border-radius: 10px;
  transition: 0.2s;
}
.sub-pop-menu-kpis{
  display: none;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  margin-right: 5px;
  margin-top: 5px;
  height: 0;
  background-color: #f3b61bcc;
  position: relative;
  top: -5px;
  float: right;
  border-radius: 10px;
  transition: 0.2s;
}
</style>