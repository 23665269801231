<template>
    <div class="kpi-details" :id="'ancre-kpi-details-'+title">
        <div class="kpi-title">
            <span class="span-title">{{ title }}</span>
            <i class="fas fa-ellipsis-h" @click="openPopMenu"></i>
        </div>
        <div :id="'pop-menu-kpis-'+title" class="pop-menu-kpis" @mouseleave="closePopMenu">
            <div :id="'sub-pop-menu-kpis-'+title" class="sub-pop-menu-kpis">
                <i class="fas fa-sync" @click="syncKpis"></i>
                <i class="fas fa-print" @click="exportKpis"></i>
                <!--        <i class="fas fa-ellipsis-h"></i>-->
            </div>
        </div>
        <KpiLoader :title="title"/>
        <div v-if="admin !== '1' && admin !== 1" class="kpi-div-content-details" :id="'kpi-details-'+title">
            <KpiListCO v-for="kpi in kpis" :key="kpi" :title="kpi.title" :kpis="kpi.details" :style="kpi.style"
                       :value="kpi.value" :link="kpi.link" :limit="limit" @callList="callList" :year="year"
                       :evolution="kpi.evolution" :versus="kpi.vs" @callMap="callMap" :datasMap="datasMap"
                       :map_filter="map_filter" :entity="entity"/>
        </div>
        <div v-else class="kpi-div-content-details" :id="'kpi-details-'+title">
            <KpiListAdmin v-for="(kpi, i) in kpis" :key="kpi" :title="kpi.title" :total="kpi.total" :kpis="kpi.details"
                          :style="kpi.style" :value="kpi.value" :link="kpi.link" :limit="limit" @callList="callList"
                          :index="i" :year="year" :evolution="kpi.evolution" :versus="kpi.vs" @callMap="callMap"
                          :datasMap="datasMap" :map_filter="map_filter" :entity="entity"/>
        </div>
    </div>
</template>

<script>
import KpiListCO from "@/components/KpiListCO";
import KpiLoader from "@/components/KpiLoader";
import volt from "@/volt";
import html2canvas from "@/services/html2canvas";
import KpiListAdmin from "@/components/KpiListAdmin";

export default {
    name: "KpiDetailsCO",
    emits: ["callList", "syncKpis", "callMap"],
    components: {KpiListAdmin, KpiListCO, KpiLoader},
    props: {
        title: String,
        kpis: Object,
        style: Object,
        open: String,
        limit: Number,
        size: Number,
        year: String,
        datasMap: Object,
        map_filter: Number,
        entity: String,
        admin: Number
    },
    mounted() {
        this.display = this.open;
        // document.getElementById('kpi-details-'+this.title).style.display = this.open;
    },
    data() {
        return {
            display: 'block'
        }
    },
    methods: {
        callMap() {
            this.$emit('callMap');
        },
        syncKpis() {
            volt.tak('kpi-loader-' + this.title).style.display = "flex";
            volt.sync = this.title;
            this.$emit('syncKpis');
        },
        async exportKpis() {
            let dom_element = volt.tak('kpi-details-' + this.title);
            await html2canvas.generatePdfElement(dom_element);
        },
        openPopMenu(e) {
            setTimeout(() => {
                volt.tak('sub-pop-menu-kpis-' + this.title).style.display = 'flex';
            }, 150);
            let popmini = volt.tak('pop-menu-kpis-' + this.title);
            // popmini.style.height = '30px';

            // let scrollWidth = popmini.scrollWidth;
            // let screenWidth = screen.width;
            let startPop = e.target.getBoundingClientRect()['left'];
            let startTopPop = e.target.getBoundingClientRect()['top'];
            let positionPop = (startPop - 65) + 'px';
            let positionPopTop = (startTopPop + 15) + 'px';
            popmini.style.height = '30px';
            popmini.style.left = positionPop;
            popmini.style.top = positionPopTop;

        },
        closePopMenu() {
            setTimeout(() => {
                setTimeout(() => {
                    volt.tak('sub-pop-menu-kpis-' + this.title).style.display = '';
                }, 20)
                volt.tak('pop-menu-kpis-' + this.title).style.height = '';
            }, 1000)
        },
        openDetails() {
            if (this.display === 'block' || this.display === '') {
                this.display = 'none';
            } else {
                this.display = 'block';
            }
        },
        callList() {
            this.$emit('callList');
        }
    }
}
</script>

<style scoped>
.kpi-details {
    margin-bottom: 50px;
    /*display: grid;*/
    /*grid-template-columns: repeat(v-bind(size), 1fr);*/
    /*grid-template-rows: repeat(1, 1fr);*/
    /*grid-column-start: 1;*/
    /*grid-column-end: v-bind(size);*/
    /*padding: 10px 15px 10px 15px*/
}

.kpi-div-content-details {
    display: grid;
    grid-template-columns: repeat(v-bind(size), 1fr);
    grid-template-rows: repeat(1, 1fr);
    transition: 0.5s;
    /*padding: 5px;*/
    grid-column-start: 1;
    grid-column-end: v-bind(size);
}

.kpi-title {
    display: flex;
    /*grid-column-start: 1;*/
    /*grid-column-end: v-bind(size);*/
    justify-content: space-between;
    /*text-align: start;*/
    font-size: 14px;
    font-weight: bold;
    margin: 5px 5px 20px 5px;
    padding: 3px 10px 3px 10px;
    border-bottom: solid 1px #2c3e50;
    /*background-color: #7a7a7a55;*/
    background: linear-gradient(to right, #f5f5f5, #e3e2e2 50%);
}

.fa-solid {
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
}

.fa-ellipsis-h {
    float: right;
    cursor: pointer;
}

.fa-ellipsis-h:hover, .fa-print:hover, .fa-sync:hover {
    color: #f3751b;
}

.fa-sync, .fa-print {
    cursor: pointer;
}

.span-title {
    cursor: pointer;
}

.pop-menu-kpis {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80px;
    margin-right: 5px;
    margin-top: 5px;
    height: 0;
    background-color: #f3b61bcc;
    position: fixed;
    /*top: 0;*/
    /*left: 15px;*/
    float: right;
    border-radius: 10px;
    transition: 0.2s;
}

.sub-pop-menu-kpis {
    display: none;
    justify-content: space-around;
    align-items: center;
    width: 80px;
    margin-right: 5px;
    margin-top: 5px;
    height: 0;
    background-color: #f3b61bcc;
    position: relative;
    top: -5px;
    float: right;
    border-radius: 10px;
    transition: 0.2s;
}
</style>