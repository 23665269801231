<template>
  <div class="jauge-radius" @click="callList">
    <div class="kpi-subtitle" :style="style.title">{{title}}</div>
    <div class="gauge-chart">
      <canvas :id="'gauge-'+title" @click="callList"></canvas>
    </div>
  </div>
</template>

<script>
import volt from "@/volt";
import * as charts_obs from "@/charts_obs";

export default {
  name: "JaugeRadiusV2",
  emits: ["callLinkPri", "callLinkSec"],
  props: {
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String
  },
  data(){
    return {
      chart: {}
    }
  },
  mounted() {
    this.chart = charts_obs.gauge(this.title, this.value, this.kpis);
  },
  methods:{
    callList(){
      if(this.link.name) {
        volt.list = {
          name: this.link.name,
          value: this.link.value,
          size: this.link.size,
          type: this.link.type,
          limit: this.limit,
          offset: 0,
          order: 1,
          by: ''
        };
        this.$emit('callLinkPri');
      }
    },
  }
}
</script>

<style scoped>
.jauge-radius{
  height: 100%;
  overflow: hidden;
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: row;
  height: 10%;
}
.gauge {
  position: relative;
}



</style>