<template>
  <div class="kpi-subtitle" :style="style.title">{{title}}</div>
      <div class="geauge-kpi-div">
        <div v-if="kpis.length > 0" class="geauge-kpi">
          <div v-for="elem in jauge_elem" :key="elem" :title="elem.value+' %'" :style="'cursor:pointer;height:100%;border-radius:'+elem['radius']+';width:'+ elem['width']+';background-color:'+ elem['background-color']" :data-name="elem['data-name']" :data-link="elem['data-link']" :data-size="elem['data-size']" :data-type="elem['data-type']" @click="callLinkSec">
          </div>
        </div>
        <div v-if="kpis.length > 0" class="geauge-kpi-val">
          <div v-for="elem in jauge_elem" :key="elem" :title="elem.value+' %'" :style="'font-size:15px;border-right:solid 1px '+ elem['background-color']+';border-left:solid 1px '+ elem['background-color']+';cursor:pointer;width:'+ elem['width']+';color:'+ elem['background-color']" :data-name="elem['data-name']" :data-link="elem['data-link']" :data-size="elem['data-size']" :data-type="elem['data-type']" @click="callLinkSec">
            {{ number_format(elem.value) }} %
          </div>
        </div>
      </div>
</template>

<script>
import volt from "@/volt";

export default {
  name: "JaugeLine",
  emits: ["callLinkPri", "callLinkSec"],
  props:{
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String
  },
  data(){
    return{
      backcolor : "",
      annee : "",
      jauge_elem : []
    }
  },
  mounted() {
    this.jauge_elem =[];
    for(let kpi in this.kpis){
      for(let kp in this.kpis[kpi].value){
        let val = this.kpis[kpi].value[kp];
        let color = this.kpis[kpi].style.value["color"];
        this.jauge_elem.push({
          "width" : (val*100/ this.value)+'%',
          "value" : (val*100/ this.value).toFixed(2),
          "background-color" :color,
          "data-name" :this.kpis[kpi].link.name,
          "data-link" : this.kpis[kpi].link.value,
          "data-size" : this.kpis[kpi].link.size,
          "data-type": this.kpis[kpi].link.type,
          "radius" : "0"
        })
      }
    }
    this.jauge_elem[0]['radius'] =  "10px 0 0 10px";
    this.jauge_elem[(this.jauge_elem.length - 1)]['radius'] = "0 10px 10px 0";
  },
  watch:{
    kpis(){
      this.jauge_elem =[];
      for(let kpi in this.kpis){
        for(let kp in this.kpis[kpi].value){
          let val = this.kpis[kpi].value[kp];
          let color = this.kpis[kpi].style.value["color"];
          this.jauge_elem.push({
            "width" : (val*100/ this.value)+'%',
            "background-color" :color,
            "data-name" :this.kpis[kpi].link.name,
            "data-link" : this.kpis[kpi].link.value,
            "data-size" : this.kpis[kpi].link.size,
            "data-type": this.kpis[kpi].link.type,
            "radius" : "0"
          })
        }
      }
      this.jauge_elem[0]['radius'] =  "10px 0 0 10px";
      this.jauge_elem[(this.jauge_elem.length - 1)]['radius'] = "0 10px 10px 0";
    }
  },
  methods:{
    number_format(number){
      return volt.number_format(number);
    },
    callLinkPri(){
      volt.list =  {
        name : this.link.name,
        value : this.link.value,
        size : this.link.size,
        type : this.link.type,
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkPri');
    },
    callLinkSec(e){
      volt.list =  {
        name : e.target.getAttribute('data-name'),
        value : e.target.getAttribute('data-value'),
        size : e.target.getAttribute('data-size'),
        type : e.target.getAttribute('data-type'),
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callLinkSec');
    }
  }
}
</script>

<style scoped>
.geauge-kpi-div{
  padding: 25px;
  cursor: pointer;
  color : #d89d06;
  /*border: solid 1px #f3751b;*/
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: row;
  height: 10%;
}
.geauge-kpi{
  display: flex;
  justify-content: start;
  flex-direction: row;
  border-radius: 10px;
  width: 99%;
  height: 40px;
  margin-top: 10px;
  padding: 2px ;
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.geauge-kpi-val{
  display: flex;
  justify-content: start;
  flex-direction: row;
  width: 99%;
  padding: 0 0 0 2px;
}
@media (max-width: 1250px) {
  .kpi-subtitle{
    padding: 10px 10px 5px 10px;
    height: 5%;
  }
  .geauge-kpi-div {
    padding: 15px;
  }
}
</style>