<template>
  <div class="kpi-component">
    <KpiDetails
        :title="title"
        :kpis="kpis"
        :open="open"
        @callList="callList"
        :limit="limit"
        @syncKpis="syncKpis"
    />
  </div>
</template>

<script>
import KpiDetails from "@/components/KpiDetails";
export default {
  name: "KpiComponent",
  emits: ["callList", "syncKpis"],
  components: {KpiDetails},
  props:{
    limit : Number,
    color : String,
    backgroundColor : String,
    gridCS : String,
    gridCE : String,
    gridRS : String,
    gridRE : String,
    title : String,
    kpis : Object,
    style : Object,
    open : String
  },
  methods : {
    callList(){
      this.$emit('callList');
    },
    syncKpis(){
      this.$emit('syncKpis');
    }
  }
}
</script>

<style scoped>
.kpi-component{
  background-color: v-bind(backgroundColor);
  color: v-bind(color);
  grid-column-start: v-bind(gridCS);
  grid-column-end: v-bind(gridCE);
  /*grid-row-start: v-bind(gridRS);*/
  /*grid-row-end: v-bind(gridRE);*/
  padding: 10px;
  transition: 2s;
  height: 100%;
}
</style>