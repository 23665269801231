<template>
<!--  <div class="menu-link">-->
<!--    <div class="name-link-nav" @click="openMenu">-->
<!--      {{ name }}-->
<!--    </div>-->
<!--    <div class="choices-link-nav" :id="'choices-link-nav-'+name">-->
<!--      <div class="links-nav" :id="'links-nav-'+name">-->
<!--        <div v-for="choice in choices" :key="choice" class="choice-link" @click="callMenus(choice.link)">{{ choice.name }}</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="menu-link">
    <div class="name-link-nav">
      {{ name }}
    </div>
    <div class="choices-link-nav" :id="'choices-link-nav-'+name">
      <div class="links-nav" :id="'links-nav-'+name">
        <div v-for="choice in choices" :key="choice" class="choice-link" :data-name="name" :data-link="choice.link" @click="callMenus(choice.link)">{{ choice.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import volt from "../volt"
export default {
  name: "MenuLink",
  emits: ["callYear", "callResult", "callChart", "callEntity","callMapFilter"],
  props: {
    name : String,
    choices : Object,
    annee : String,
    nbr_result : String,
    commune : String,
    map_filter : Number
  },
  mounted() {
    volt.taks('.choice-link','',false).forEach((el)=>{
      if(el.getAttribute('data-name') === 'Cartographie'){
        el.style.color = '';
        if(el.getAttribute('data-link') === this.map_filter.toString()){
          el.style.color = '#F4AF0A';
        }
      }
    })
  },
  data(){
    return {
      clEvent : ''
    }
  },
  methods:{
    callMenus(link){
      volt.taks('.choice-link','',false).forEach((el)=>{
        if(el.getAttribute('data-name') === this.name){
          el.style.color = '';
          if(el.getAttribute('data-link') === link.toString()){
            el.style.color = '#F4AF0A';
          }
        }
      })
      if(this.name === 'Années') this.callYear(link);
      if(this.name === 'Résultats') this.callResult(link);
      if(this.name === 'Tableaux') this.callChart(link);
      if(this.name === 'Communes') this.callEntity(link);
      if(this.name === 'Cartographie') this.callMapFilter(link);
    },
    callYear(link){volt.annee = link; setTimeout(()=>{ this.$emit('callYear');},100);},
    callResult(link){volt.nbr_result = link; setTimeout(()=>{ this.$emit('callResult');},100);},
    callChart(link){volt.tableau = link; setTimeout(()=>{ this.$emit('callChart');},100);},
    callEntity(link){volt.commune = link; setTimeout(()=>{ this.$emit('callEntity');},100);},
    callMapFilter(link){volt.map_filter = link; setTimeout(()=>{ this.$emit('callMapFilter');},100);},
    // openMenu(){
    //   volt.taks('.links-nav','',false).forEach((el)=>{
    //     if(el.id !== 'links-nav-'+this.name) el.style.display = 'none';
    //   });
    //   volt.taks('.choices-link-nav','',false).forEach((el)=>{
    //     if(el.id !== 'choices-link-nav-'+this.name) el.style.height = '0px';
    //   });
    //   if( volt.tak('choices-link-nav-'+this.name).style.height === '0px' ||  volt.tak('choices-link-nav-'+this.name).style.height === ''){
    //     volt.tak('choices-link-nav-'+this.name).style.height = '20%';
    //     setTimeout(()=>{
    //       volt.tak('links-nav-'+this.name).style.display = 'flex';
    //     },200);
    //   }else{
    //     volt.tak('links-nav-'+this.name).style.display = 'none';
    //     volt.tak('choices-link-nav-'+this.name).style.height = '0px';
    //   }
    //   document.addEventListener('click', this.closeMenu, true);
    // },
    // closeMenu(){
    //   volt.taks('.links-nav','',false).forEach((el1)=>{
    //     el1.style.display = 'none';
    //   });
    //   volt.taks('.choices-link-nav','',false).forEach((el2)=>{
    //     el2.style.height = '0px';
    //   });
    //   document.removeEventListener('click', this.closeMenu, true);
    // }
  }
}
</script>

<style scoped>
.menu-link{
  width: 250px;
  margin-bottom: 15px;
}
.name-link-nav{
  margin-right: 30px;
  font-size: 18px;
  cursor: pointer;
  /*border-left: solid 2px #f3b61b;*/
  padding-left: 15px;
  padding-bottom: 5px;
  text-align: start;
  border-bottom: solid 1px #F4AF0A;
  width: 230px;
}
/*.name-link-nav:hover{*/
/*  !*font-size: 19px;*!*/
/*  color: #f3b61b;*/
/*}*/
.choices-link-nav {
  font-size: 18px;
  cursor: pointer;
  /*border-left: solid 2px #f3b61b;*/
  padding-left: 18px;
  padding-bottom: 18px;
  text-align: start;
  /*position: absolute;*/
  background-color: #dadcdf;
  width: 90%;
  max-height: 160px;
  z-index: 1000;
  transition: 0.2s;
  /*border-radius: 0 0 10px 10px;*/
  overflow: auto;
}
.links-nav{
  /*display: none;*/
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.choice-link{
  margin-top: 15px;
  font-size: 18px;
}
.choice-link:hover{
  /*font-size: 16px;*/
  color: #f3b61b;
}
</style>