<template>
  <div class="histo-block">
    <div class="histo-legend">
      <div class="kpi-subtitle" :style="style.title">{{title}}</div>
      <div class="legend-chart" :id="'legend-'+title"> </div>
    </div>
    <div class="histo-chart">
      <canvas :id="'histogram-'+title" @click="callList"></canvas>
    </div>
  </div>
</template>

<script>
import * as charts_obs from "@/charts_obs";
import volt from "@/volt";
export default {
  name: "HistoBlock",
  emits: ["callLinkPri"],
  props : {
    title : String,
    style : Object,
    datas : Object,
    type : String,
    limit : Number,
    link : Object,
    size : String,
    year : String
  },
  data(){
    return {
      chart: {}
    }
  },
  mounted() {

    let data_values = [];
    let data_links = [];
    this.datas.forEach((data)=>{
      data_values.push(data.value);
      data_links.push(data.link.name);
    })
    if(this.type === 'multi'){
      this.chart = charts_obs.multi_bar(this.title, data_values, this.style.legend, this.style.legend_html, data_links, this.type);
    }else{
      this.chart = charts_obs.histogram(this.title, data_values, this.style.legend, this.style.legend_html, data_links);
    }
  },
  methods: {
    callList(){
      setTimeout(()=>{
        if(volt.list.name && volt.list.name !== '')this.$emit('callLinkPri')
      },300);
      // volt.list =  {
      //   name : this.link.name,
      //   value : this.link.value,
      //   size : this.link.size,
      //   type : this.link.type,
      //   limit : this.limit,
      //   offset : 0,
      //   order : 1,
      //   by : ''
      // };
      // this.$emit('callLinkPri');
    }
  }
}
</script>

<style scoped>
.histo-block{
  /*max-height: 90%;*/
  /*min-width: 90%;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
}
.histo-legend{
  width: 30%;
  display: flex;
  flex-direction: column;
}
.legend-chart{
  height: 65%;
}
.histo-chart{
  width: 70%;
  height: 100%;
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px 15px 0 15px;
  display: flex;
  flex-direction: row;
  height: 30%;
}
@media (max-width: 1250px) {
  .histo-legend{
    width: 100%;
    flex-direction: row;
  }
  .histo-block{
    flex-direction: column;
  }
  .histo-chart{
    width: 100%;
  }
  .legend-chart{
    font-size: 10px;
    margin: 20px 10px 0 0;
  }
  .histo-block{
    flex-direction: column;
    font-size: 10px;
  }
}
</style>