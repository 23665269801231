<template>
<!--  <div class="menu-link">-->
<!--    <div class="name-link-nav" @click="openMenu">-->
<!--      {{ name }}-->
<!--    </div>-->
<!--    <div class="choices-link-nav" :id="'choices-link-nav-'+name">-->
<!--      <div class="links-nav" :id="'links-nav-'+name">-->
<!--        <div v-for="choice in choices" :key="choice" class="choice-link" @click="callMenus(choice.link)">{{ choice.name }}</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <div class="menu-link">
    <div class="name-link-nav">
      {{ name }}
    </div>
    <div class="choices-link-nav" :id="'choices-link-nav-'+name">
<!--      <div class="links-nav" :id="'links-nav-'+name">-->
<!--        <div v-for="choice in choices" :key="choice" class="choice-link" @click="callMenus(choice.link)">{{ choice.name }}</div>-->
<!--      </div>-->
      <select class="links-nav" :id="'links-nav-'+name" @change="callMenus">
        <option v-for="choice in choices" :key="choice" :class="'choice-link choice-'+name" :data-name="choice.link" :value="choice.link">{{ choice.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import volt from "../volt"
export default {
  name: "LinkSelect",
  emits: ["callYear", "callResult", "callChart", "callEntity"],
  props: {
    name : String,
    choices : Object,
    year : String,
    limit : Number,
    entity : String
  },
  mounted() {
    volt.taks('.choice-'+this.name,'',false).forEach((el)=>{
      if(this.name === 'Années' && el.getAttribute("data-name") === this.year) el.setAttribute('selected','selected');
      if(this.name === 'Résultats'  && el.getAttribute("data-name") === this.limit.toString()) el.setAttribute('selected','selected');
      if(this.name === 'Communes' && el.getAttribute("data-name") === this.entity) el.setAttribute('selected','selected');
    });
  },
  updated() {
    volt.taks('.choice-'+this.name,'',false).forEach((el)=>{
      if(this.name === 'Années' && el.getAttribute("data-name") === this.year) el.setAttribute('selected','selected');
      if(this.name === 'Résultats'  && el.getAttribute("data-name") === this.limit.toString()) el.setAttribute('selected','selected');
      if(this.name === 'Communes' && el.getAttribute("data-name") === this.entity) el.setAttribute('selected','selected');
    });
  },
  data(){
    return {
      clEvent : '',
    }
  },
  watch:{
    year(){
      volt.taks('.choice-'+this.name,'',false).forEach((el)=>{
        if(this.name === 'Années' && el.getAttribute("data-name") === this.year) el.setAttribute('selected','selected');
      });
    },
    limit(){
      volt.taks('.choice-'+this.name,'',false).forEach((el)=>{
        if(this.name === 'Résultats'  && el.getAttribute("data-name") === this.limit) el.setAttribute('selected','selected');
      });
    },
    entity(){
      volt.taks('.choice-'+this.name,'',false).forEach((el)=>{
        if(this.name === 'Communes' && el.getAttribute("data-name") === this.entity) el.setAttribute('selected','selected');
      });
    }
  },
  methods:{
    callMenus(e){
      volt.taks('.choice-'+this.name,'',false).forEach((el)=>{
        if(this.name === 'Années' && el.getAttribute("data-name") === this.year) el.setAttribute('selected','selected');
        if(this.name === 'Résultats'  && el.getAttribute("data-name") === this.limit) el.setAttribute('selected','selected');
        if(this.name === 'Communes' && el.getAttribute("data-name") === this.entity) el.setAttribute('selected','selected');
      });
      if(this.name === 'Années') this.callYear(e.target.value);
      if(this.name === 'Résultats') this.callResult(e.target.value);
      if(this.name === 'Tableaux') this.callChart(e.target.value);
      if(this.name === 'Communes') this.callEntity(e.target.value);
    },
    callYear(link){volt.annee = link; setTimeout(()=>{ this.$emit('callYear');},100);},
    callResult(link){volt.nbr_result = link; setTimeout(()=>{ this.$emit('callResult');},100);},
    callChart(link){volt.tableau = link; setTimeout(()=>{ this.$emit('callChart');},100);},
    callEntity(link){volt.commune = link; setTimeout(()=>{ this.$emit('callEntity');},100);},
    // openMenu(){
    //   volt.taks('.links-nav','',false).forEach((el)=>{
    //     if(el.id !== 'links-nav-'+this.name) el.style.display = 'none';
    //   });
    //   volt.taks('.choices-link-nav','',false).forEach((el)=>{
    //     if(el.id !== 'choices-link-nav-'+this.name) el.style.height = '0px';
    //   });
    //   if( volt.tak('choices-link-nav-'+this.name).style.height === '0px' ||  volt.tak('choices-link-nav-'+this.name).style.height === ''){
    //     volt.tak('choices-link-nav-'+this.name).style.height = '20%';
    //     setTimeout(()=>{
    //       volt.tak('links-nav-'+this.name).style.display = 'flex';
    //     },200);
    //   }else{
    //     volt.tak('links-nav-'+this.name).style.display = 'none';
    //     volt.tak('choices-link-nav-'+this.name).style.height = '0px';
    //   }
    //   document.addEventListener('click', this.closeMenu, true);
    // },
    // closeMenu(){
    //   volt.taks('.links-nav','',false).forEach((el1)=>{
    //     el1.style.display = 'none';
    //   });
    //   volt.taks('.choices-link-nav','',false).forEach((el2)=>{
    //     el2.style.height = '0px';
    //   });
    //   document.removeEventListener('click', this.closeMenu, true);
    // }
  }
}
</script>

<style scoped>
.menu-link{
  width: 270px;
  margin-bottom: 15px;
}
.name-link-nav{
  margin-right: 30px;
  font-size: 18px;
  cursor: pointer;
  /*border-left: solid 2px #f3b61b;*/
  padding-left: 15px;
  padding-bottom: 5px;
  text-align: start;
  border-bottom: solid 1px #F4AF0A;
  width: 230px;
}
/*.name-link-nav:hover{*/
/*  !*font-size: 19px;*!*/
/*  color: #f3b61b;*/
/*}*/
.choices-link-nav {
  font-size: 18px;
  cursor: pointer;
  /*border-left: solid 2px #f3b61b;*/
  /*padding-left: 15px;*/
  text-align: start;
  /*position: absolute;*/
  background-color: #dadcdf;
  width: 90%;
  max-height: 150px;
  z-index: 1000;
  transition: 0.2s;
  /*border-radius: 0 0 10px 10px;*/
  overflow: auto;
}
.links-nav{
  /*display: none;*/
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  font-size: 17px;
  padding: 5px 0 5px 15px;
  background-color: #dadcdf;
  border: none;
  border-radius: 5px;
}
.choice-link{
  margin-top: 15px;
  font-size: 15px;
}
.choice-link:hover{
  /*font-size: 16px;*/
  color: #f3b61b;
}
</style>