<template>
  <div class="map-loader-div">
    <div class="map-loader"></div>
  </div>
</template>

<script>
export default {
  name: "MapLoader"
}
</script>

<style scoped>
.map-loader-div{
  grid-column-start: 10;
  grid-column-end: 11;
  grid-row-start: 9;
  grid-row-end: 11;
  border-radius: 50px;
  width: 500px;
  height: 420px;
  background-image: url("/public/fr-03.png");
  background-repeat: no-repeat;
  background-position: center;
}
.map-loader {
  position: relative;
  left: 240px;
  top: 180px;
  width:  48px;
  height: 48px;
  background: #de3500;
  transform: rotateX(65deg) rotate(45deg);
/*// remove bellows command for perspective change*/
/*  transform: perspective(200px) rotateX(65deg) rotate(45deg);*/
  color: #f3b61b;
  animation: layers1 1s linear infinite alternate;
}
.map-loader:after {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.7);
  animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
  0% { box-shadow: 0px 0px 0 0px  }
  90% , 100% { box-shadow: 20px 20px 0 -4px  }
}
@keyframes layerTr {
  0% { transform:  translate(0, 0) scale(1) }
  100% {  transform: translate(-25px, -25px) scale(1) }
}
</style>