/* eslint-disable */
const volt = require("@/volt");

module.exports.getUsersEntity = async (id, entity, year) => {
  return await volt.get("users/entity/find?entity_id="+id+"&year="+year, entity);
}
module.exports.getUsers = async (entities, year) => {
    return await volt.get("users/findAdmin?year="+year, entities);
}
module.exports.getUserDetails = async (entity_id , user_id, entity, year) => {
    return await volt.get("users/findAll?entity_id="+ entity_id +"&user="+ user_id +"&year="+ year, entity);
}
module.exports.getLastActions = async (entities, type, length) => {
    return await volt.get("users/findLastActions?type=" + type + "&length="+ length, entities);
}