<template>
  <div class="select-conform-field">
    <div class="value-select-field" :id="'value-select-field-'+annonce+'-'+conformite.field">{{value}}</div><div><i v-if="!historique && user.level != 3" class="fas fa-pen-alt" @click="editValue"></i></div>
  </div>
  <div class="select-choices-conform-field" :id="'select-choices-conform-field-'+annonce+'-'+conformite.field">
<!--    <div class="div-close-choice-conform"><i class="far fa-window-close" @click="closePopup"></i></div>-->
    <div class="select-choice-conform field-conform" @click="selectValue('Conforme')">Conforme</div>
    <div class="select-choice-conform field-non-conform" @click="selectValue('Non conforme')">Non conforme</div>
    <div class="select-choice-conform field-a-verifier" @click="selectValue('A vérifier')">A vérifier</div>
  </div>
</template>

<script>
import volt from "../volt";
export default {
  name: "SelectConformite",
    emits: ['changeValue'],
  props:{
    conformite : Object,
    annonce : String,
    annonces : Object,
    user : {},
    entity : String,
      historique: Boolean
  },
  data(){
    return{
      value : '',
      details : {},
      origin : '',
      color : '#454545',
      colors : {
        'Conforme' : "#03710c",
        'Non conforme' : "#b70303",
        'A vérifier' : "#d18a03",
      }
    }
  },
  mounted() {
    this.updateValues();
  },
  updated() {
    this.closePopup();
  },
  methods: {
    editValue(e) {
      if (volt.tak('select-choices-conform-field-' + this.annonce + '-' + this.conformite.field).style.display === 'block'){
        this.closePopup();
        volt.tak('select-choices-conform-field-' + this.annonce + '-' + this.conformite.field).style.display = '';
      }else{
        this.closePopup();
        let startPop = e.target.offsetLeft;
        let startPopTop = e.target.offsetTop;
        e.target.style.color = "#f3b61b";
        volt.tak('value-select-field-'+this.annonce+'-'+this.conformite.field).style.border = "solid 1px #f3b61b";
        volt.tak('select-choices-conform-field-' + this.annonce + '-' + this.conformite.field).style.display = 'block';
        volt.tak('select-choices-conform-field-' + this.annonce + '-' + this.conformite.field).style.top = (startPopTop+22)+'px';
        volt.tak('select-choices-conform-field-' + this.annonce + '-' + this.conformite.field).style.left = (startPop-123)+'px';
      }
    },
    selectValue(value){
      // this.closePopup();

      let data = {
        field : this.conformite.field,
        annonce : this.annonce,
        annonces : this.annonces,
        value : value,
        from : this.user
      }
      // if(this.annonces.length > 0){
      //   this.value = value + ' *';
      //   this.color = this.colors[value];
      // }else {
        if(data.annonces.length > 0) {
          if(confirm('Voulez-vous vraiment modifier la conformité des '+data.annonces.length+' annonces ?')){
            volt.post('obs/update/conformite/up', data, '', this.entity).then((response) => {
              if (value !== this.origin) {
                this.value = value + ' *';
              } else {
                this.value = value;
              }
              this.color = this.colors[value];
              console.log(response);
            });
          }
        }else{
          volt.post('obs/update/conformite/up', data, '', this.entity).then((response) => {
              this.$emit('changeValue', {annonce:response.annonce});
            if (value !== this.origin) {
              this.value = value + ' *';
            } else {
              this.value = value;
            }
            this.color = this.colors[value];
            console.log(response);
          });
        }
    },
    closePopup(){
      volt.taks('.comment-textarea','',false).forEach((el)=>{
        el.style.height = '';
      });
      volt.taks('.value-select-field','',false).forEach((el)=>{
        el.style.border = '';
      });
      volt.taks('.select-choices-conform-field','',false).forEach((el)=>{
        el.style.display = '';
      });
      volt.taks('.fa-pen-alt','',false).forEach((el)=>{
        el.style.color = '';
      });
    },
    updateValues(){
      this.value = this.conformite.value;
      this.details = this.conformite.details;
      this.origin = this.conformite.origin;
      this.color = this.colors[this.value];
      if(this.details !== undefined){
        if(this.value !== '' && this.value !== this.origin && this.details.from !== ''){
          this.value = this.value + ' *';
        }
      }
    }
  },
  watch:{
    annonce(){
      this.updateValues();
    },
    annonces(){
      this.updateValues();
    },
      historique(){
          this.updateValues();
      }
  }
}
</script>

<style scoped>
.select-conform-field{
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.value-select-field{
  width: 75%;
  padding: 5px 3px 5px 3px;
  vertical-align: center;
  box-shadow: inset 1px 1px 5px #7a7a7a;
  border-radius: 5px 0 0 5px;
  background-color: #efeeee;
  color: v-bind(color);
}
.fa-pen-alt{
  border: solid 1px #7a7a7a;
  padding: 5px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}
.fa-pen-alt:active{
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.select-choices-conform-field{
  display: none;
  position: fixed;
  width: 112px;
  font-size: 12px;
  background-color: #efeeee;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  border-right: solid 1px #f3b61b;
  border-bottom: solid 1px #f3b61b;
  border-left: solid 1px #f3b61b;
}
.select-choice-conform{
  border-radius: 5px;
  padding: 5px;
  /*border: solid 1px #7a7a7a;*/
  margin-bottom: 3px;
  cursor: pointer;
  background-color: #efeeee;
}
.select-choice-conform:hover{
  background-color: #dfdfdf;
}
.select-choice-conform:active{
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.field-conform{
  color:v-bind(colors['Conforme'])
}
.field-non-conform{
  color:v-bind(colors['Non conforme'])
}
.field-a-verifier{
  color:v-bind(colors['A vérifier'])
}
.fa-window-close{
  font-size: 18px;
  cursor: pointer;
}
.fa-window-close:active{
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.div-close-choice-conform{
  width: 100%;
  text-align: right;
  margin-bottom: 5px;
}
</style>