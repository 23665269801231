<template>
  <div class="kpi-list">
    <div class="kpi-subtitle" :style="style.title">
      <i class="fa-regular fa-circle"></i> {{title}} :
      <div v-if="link.name === '' && link.type !== 'geauge'" class="kpi-alone" :style="style.value" @click="callLinkPri"> {{ value }}</div>
      <div v-else-if="link.type === 'text'" class="kpi-alone-link" :style="style.value" :data-type="link.type" @click="callLinkPri"> {{ value }} <i class="fa-solid fa-align-left"></i></div>
      <div v-else-if="link.type === 'list'" class="kpi-alone-link" :style="style.value" :data-type="link.type" @click="callLinkPri"> {{ value }} <i class="fas fa-list"></i></div>
      <div v-else-if="link.type === 'geauge'" class="kpi-alone-link" :style="style.value" :data-type="link.type"></div>
      <div v-else class="kpi-alone-link" :style="style.value" :data-type="link.type" @click="callLinkPri"> {{ value }}</div>
    </div>

    <div v-if="link.type === 'cluster'" class="cluster-div-prime">
      <div v-if="kpis.length > 0" class="cluster-element">
        <div v-for="kpi in kpis" :key="kpi" >
          <span style="display: none">{{nb=0}}</span>
          <div v-for="kp in kpi.value" :key="kp" class="cluster-div" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type" @click="callLinkSec">
            <div :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
              <div class="title-cluster" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
                {{ Object.keys(kpi.value)[nb] }}
              </div>
              <span style="display: none">{{i=0}}</span>
              <div v-for="k in kp" :key="k" class="text-cluster" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kp['Référencés']" :data-type="kpi.link.type">
                {{Object.keys(kp)[i]}} : {{ k }}
                <span style="display: none">{{i++}}</span>
              </div>
            </div>
            <span style="display: none">{{nb++}}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="link.type === 'geauge'">
      <div v-if="kpis.length > 0" class="geauge-kpi">
        <div v-for="elem in jauge_elem" :key="elem" :title="elem.value+' %'" :style="'cursor:pointer;height:100%;border-radius:'+elem['radius']+';width:'+ elem['width']+';background-color:'+ elem['background-color']" :data-name="elem['data-name']" :data-link="elem['data-link']" :data-size="elem['data-size']" :data-type="elem['data-type']" @click="callLinkSec">
        </div>
      </div>
      <div v-if="kpis.length > 0" class="geauge-kpi-val">
        <div v-for="elem in jauge_elem" :key="elem" :title="elem.value+' %'" :style="'font-size:12px;border-right:solid 1px #7a7a7a;border-left:solid 1px #7a7a7a;cursor:pointer;width:'+ elem['width']" :data-name="elem['data-name']" :data-link="elem['data-link']" :data-size="elem['data-size']" :data-type="elem['data-type']" @click="callLinkSec">
          {{ elem.value }} %
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="kpis.length > 0">
        <div v-for="kpi in kpis" :key="kpi" >
          <span style="display: none">{{nb=0}}</span>
          <div v-for="kp in kpi.value" :key="kp" class="kpi-element">
            <div v-if="kpi.link.type !== 'cluster'" :style="kpi.style.element"><i class="fa-solid fa-play"></i> {{ Object.keys(kpi.value)[nb] }}:
              <span v-if="kpi.link.name === ''" class="kpi-alone" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type">{{ kp }}</span>
              <span v-else-if="kpi.link.type === 'text'" class="kpi-alone-link" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{ kp }} <i class="fa-solid fa-align-left"></i></span>
              <span v-else-if="kpi.link.type === 'list'" class="kpi-alone-link" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{ kp }} <i class="fas fa-list"></i></span>
              <span v-else class="kpi-alone-link" :style="kpi.style.value" :data-name="kpi.link.name" :data-link="kpi.link.value" :data-size="kpi.link.size" :data-type="kpi.link.type" @click="callLinkSec">{{ kp }}</span>
            </div>
            <span style="display: none">{{nb++}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import volt from "../volt";
export default {
  name: "KpiList",
  components : {},
  emits: ["callList"],
  props:{
    limit : Number,
    title : String,
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object
  },
  mounted() {
    if(Object.keys(this.kpis).length > 0){
      this.weigthTitle = 600;
      this.margBotEle = '2%';
    }
    if(this.link.type === 'geauge'){
      this.jauge_elem =[];
      for(let kpi in this.kpis){
        for(let kp in this.kpis[kpi].value){
          let val = this.kpis[kpi].value[kp];
          let color = this.kpis[kpi].style.value["color"];
          this.jauge_elem.push({
            "width" : (val*100/ this.value)+'%',
            "value" : (val*100/ this.value).toFixed(2),
            "background-color" :color,
            "data-name" :this.kpis[kpi].link.name,
            "data-link" : this.kpis[kpi].link.value,
            "data-size" : this.kpis[kpi].link.size,
            "data-type": this.kpis[kpi].link.type,
            "radius" : "0"
          })
        }
      }
      this.jauge_elem[0]['radius'] =  "10px 0 0 10px";
      this.jauge_elem[(this.jauge_elem.length - 1)]['radius'] = "0 10px 10px 0";
    }
  },
  watch:{
    kpis(){
      if(this.link.type === 'geauge'){
        this.jauge_elem =[];
        for(let kpi in this.kpis){
          for(let kp in this.kpis[kpi].value){
            let val = this.kpis[kpi].value[kp];
            let color = this.kpis[kpi].style.value["color"];
            this.jauge_elem.push({
              "width" : (val*100/ this.value)+'%',
              "background-color" :color,
              "data-name" :this.kpis[kpi].link.name,
              "data-link" : this.kpis[kpi].link.value,
              "data-size" : this.kpis[kpi].link.size,
              "data-type": this.kpis[kpi].link.type,
              "radius" : "0"
            })
          }
        }
        this.jauge_elem[0]['radius'] =  "10px 0 0 10px";
        this.jauge_elem[(this.jauge_elem.length - 1)]['radius'] = "0 10px 10px 0";
      }
    }
  },
  data(){
    return{
      jauge_elem : [],
      weigthTitle : 0,
      margBotEle : '2px'
    }
  },
  methods:{
    callLinkPri(){
      volt.list =  {
        name : this.link.name,
        value : this.link.value,
        size : this.link.size,
        type : this.link.type,
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callList');
    },
    callLinkSec(e){
      volt.list =  {
        name : e.target.getAttribute('data-name'),
        value : e.target.getAttribute('data-value'),
        size : e.target.getAttribute('data-size'),
        type : e.target.getAttribute('data-type'),
        limit : this.limit,
        offset : 0,
        order : 1,
        by : ''
      };
      this.$emit('callList');
    }
  }
}
</script>

<style scoped>
.kpi-list{
  padding-left: 1%;
  display: flex;
  flex-direction: column;
  margin-bottom: v-bind(margBotEle);
}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  margin: 2px;
  padding: 0;
  font-weight: v-bind(weigthTitle);
  display: flex;
  flex-direction: row;
}
.kpi-element{
  padding-left: 1%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  text-align: start;
  margin:2px 2px 5px 60px;
}
.cluster-div-prime{
  display: flex;
  flex-direction: row;
}
.cluster-element{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 20px;
}
.cluster-div{
  margin: 5px;
  background-color: #041f86;
  color: antiquewhite;
  width: 170px;
  padding: 10px;
  border-radius: 5px;
  height: 70px;
  cursor: pointer;
}
.title-cluster{
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: solid 2px #8098f3;
  text-align: left;
}
.text-cluster{
  font-size: 13px;
  margin-bottom: 5px;
}
.fa-circle{
  font-size: 8px;
  margin: 5px;
}
.fa-play{
  font-size: 10px;
  margin-right: 10px;
}
.kpi-alone{
  margin-left: 1px;
}
.kpi-alone-link{
  margin-left: 1px;
  cursor: pointer;
  color : #d89d06;
}
.geauge-kpi{
  display: flex;
  justify-content: start;
  flex-direction: row;
  border-radius: 10px;
  width: 99%;
  height: 30px;
  margin-top: 10px;
  padding: 2px ;
  box-shadow: inset 1px 1px 5px #7a7a7a;
}
.geauge-kpi-val{
  display: flex;
  justify-content: start;
  flex-direction: row;
}
</style>