<template>
  <div class="kpi-list">
    <div class="visuel-brick visuel-block">
      <div class="kpi-subtitle">{{title.toUpperCase()}}</div>
      <div>
        <div v-if="style.visuel === 'logs'" class="histo-chart-logs">
          <div v-for="kpi in kpis" :key="kpi" class="log-line-admin">
            <div class="date-log-admin"><i class="fa-solid fa-clock"> </i> {{kpi.date}}</div>
            <div class="route-log-admin"> <i class="fa-solid fa-road"></i> {{kpi.title}}</div>
            <div class="user-log-admin"> <i class="fa-solid fa-user"></i> {{kpi.name}}</div>
          </div>
        </div>
        <div v-else class="histo-chart">
          <canvas class="histo-connexion-admin" :id="'histogram-connexion-'+title"></canvas>
        </div>
      </div>
      <div class="visuel-block-multi">
      </div>
    </div>



  </div>
</template>

<script>
import volt from "../volt";
// import MultiBlock from "@/elements/MultiBlock";
// import JaugeLine from "@/elements/JaugeLine";
// import ClusterBlock from "@/elements/ClusterBlock";
// import SimpleBlock from "@/elements/SimpleBlock";
// import MapsVue from "@/components/MapsVue";
// import HistoBlock from "@/elements/HistoBlock";
// import LineBlock from "@/elements/LineBlock";
// import JaugeRadius from "@/elements/JaugeRadius";
// import BlockGraph from "@/elements/BlockGraph";
// import MultiBlock from "@/elements/MultiBlock.vue";

// import HistoBlockHorizontal from "@/elements/HistoBlockHorizontal.vue";

import * as charts_obs from "@/charts_obs";

export default {
  name: "KpiListAdmin",
  components : {
    // HistoBlockHorizontal
    // SimpleBlock,
    // MultiBlock
    // BlockGraph, JaugeRadius, LineBlock, HistoBlock, MapsVue, SimpleBlock, ClusterBlock, JaugeLine, MultiBlock
  },
  emits: ["callList", "callMap"],
  props:{
    limit : Number,
    title : [String, Object],
    kpis : [Array],
    value : [String, Number],
    style : Object,
    link : Object,
    size : String,
    year : String,
    evolution : [String, Number],
    versus : Object,
    datasMap : Object,
    map_filter : Number,
    entity : String,
    index : Number,
    total : Number
  },
  mounted() {
    if(this.style.visuel === "histo-horizontal"){
      this.chart = charts_obs.histogram_horizontal('connexion-'+this.title, this.initHisto()[0], false, false, this.initHisto()[1], 'multi');
    }else if(this.style.visuel === "histo"){
      this.chart = charts_obs.histogram('connexion-'+this.title, this.initHisto()[0], false, false, this.initHisto()[1], this.total);
    }
    this.gridCS = ((this.index + 1)).toString();
    this.gridCE = (parseInt(this.gridCS) + 3).toString();
  },
  watch:{
  },
  data(){
    return{
      jauge_elem : [],
      weigthTitle : 0,
      margBotEle : '2px',
      backcolor : "",
      maps : {},
      title_ : "",
      irisMap : {},
      arrMap : {},
      gridCS : "1",
      gridCE : "2",
    }
  },
  methods:{
    initHisto(){
      let data_values = [];
      let data_links = [];
      this.kpis.forEach((data)=>{
        data_values.push(JSON.parse('{"'+volt.firstToUpper(data.title)+'":'+ data.value +'}'));
        data_links.push(data.title);
      })
      return [data_values, data_links];
    },
  }
}
</script>

<style scoped>
.histo-connexion-admin{
  font-size: 10px;
}
.histo-chart-logs{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.log-line-admin{
  font-size: 10px;
  padding: 3px 0 3px 15px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  text-wrap: nowrap;
  border-bottom: #aaaaaa55 solid 1px;
}
.date-log-admin{
  color: #0e7f65;
  display: flex;
  min-width: 25%;
  flex-direction: row;
  justify-content: start;
}
.route-log-admin{
  color: #041f86;
  min-width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.user-log-admin{
  color: #b58408;
  min-width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.fa-solid{
  margin: 0 5px 0 10px;
}
.name-connexion-admin{
  font-size: 12px;
  padding: 3px 0 3px 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: start;
  text-wrap: nowrap;
}
.value-connexion-admin{
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 3px 15px 3px 0;
}
.kpi-list{
  padding: 5px;
  display: grid;
  margin-bottom: v-bind(margBotEle);
}
.visuel-brick{
  border: solid 1px #7a7a7a;
  border-radius: 10px;
  height: 200px;
  overflow: auto;
}
.visuel-block{
  /*cursor: pointer;*/
}
.visuel-map{
  height:400px;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(20, 1fr);
}
.visuel-block-graph{

}
.visuel-graph-line{

}
.visuel-block-multi{

}
.visuel-jauge{

}
.visuel-graph-doughnut{

}
.kpi-subtitle{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  display: flex;
  flex-direction: row;
}
.kpi-subtitle-map{
  text-align: start;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 2;
}
@media (max-width: 1250px) {
  .histo-chart{
    width: 1000px;
    overflow: auto;
  }
  .visuel-brick{
    height: 150px;
    padding-bottom: 10px;
  }
  .visuel-block-multi{
    height: max-content;
    padding-bottom: 10px;
  }
  .visuel-map{
    height: 300px;
  }
  .visuel-graph-histograme{
    height: max-content;
  }
  .visuel-graph-line{
    height: max-content;
  }
}
@media (max-width: 800px) {
  .log-line-admin{
    flex-direction: column;
    border-bottom: #aaaaaa55 solid 1px;
  }
  .kpi-list{
    padding: 5px;
    display: grid;
    grid-column-start: 1;
    grid-column-end: 13;
    margin-bottom: v-bind(margBotEle);
  }
  .visuel-brick{
    height: max-content;
    padding-bottom: 10px;
  }
  .visuel-map{
    height: 300px;
  }
  .visuel-block-multi{
    height: max-content;
  }
  .visuel-geauge{
    height: max-content;
  }
  .visuel-graph-histograme{
    height: max-content;
  }
}
</style>