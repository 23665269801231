const html2PDF = require('jspdf-html2canvas');

module.exports.generatePdf = async function (urlImage) {
    if(urlImage === undefined) {urlImage = "logo_touriz.png"}
    // const pages = document.querySelectorAll(".page-export")
    const pages = document.querySelectorAll(".export-elem")
    return await html2PDF(pages, {
        jsPDF: {
            unit: 'px',
            format: 'a4',
            orientation: "portrait"
        },
        imageType: 'image/png',
        imageQuality: 1,
        margin: {
            top: 90,
            right: 20,
            bottom: 20,
            left: 20,
        },
        watermark: {
            src: urlImage,
            handler({pdf, imgNode}) {
                pdf.addImage(imgNode, "png", pdf.getPageWidth() / 2 - 90, 15, 180, 50)
            }
        },
        autoResize: true,
    })
}
module.exports.generatePdfElement = async function (dom_element) {
        // const pages = document.querySelectorAll(".page")
        // pages[0].style.paddingTop = "100px"
        // const checkMarks = document.querySelectorAll(".check-mark-required")
        // checkMarks.forEach(check => check.style.boxShadow = "none")

        return await html2PDF(dom_element, {
            jsPDF: {
                unit: 'px',
                format: 'a4',
                orientation: "l",
            },
            imageQuality: 1,
            autoResize: true
        })
}